<template>
  <main class="connections">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <SectionUserInfo
        v-if="forClient"
        :connections="connections"
        :connectionsShow="true"
      >
        <!-- <template #someTips>
          <div class="section-userInfo-someTips">
            A connection is <span class="font-weight-bold">xxxxxxxxxxx</span>
          </div>
        </template> -->
      </SectionUserInfo>
      <section class="section">
        <div class="section-title connections-title">Reporting Connections</div>
        <div class="section-table connections-table">
          <div class="section-table-status">
            <router-link
              class="btn btn-primary"
              to="/connections/new"
              v-if="forClient"
              >New Connection</router-link
            >
          </div>
          <div class="section-table-content">
            <table class="table table-hover" v-if="connectionShowHandler">
              <thead>
                <tr>
                  <th scope="col" class="connections-td-status">Status</th>
                  <th scope="col" class="connections-td-view"></th>
                  <th scope="col" class="connections-td-source">
                    Facebook Ad Account
                  </th>
                  <th scope="col" class="connections-td-destination">
                    SA360 Social Engine Account
                  </th>
                  <th scope="col" class="connections-td-last">Last Run</th>
                  <th
                    scope="col"
                    class="connections-th-btn connections-td-btn dropdown-td"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item of renderConnections" :key="item.id">
                  <td
                    scope="row"
                    class="connections-td-status"
                    :class="{
                      'text-primary': item.status === 'ACTIVE',
                      'text-danger': item.status === 'INACTIVE'
                    }"
                  >
                    {{ item.status }}
                  </td>
                  <td class="connections-td-view">
                    <router-link
                      class="btn-link"
                      :to="`/connections/${item.id}/view`"
                      ><span class="material-icons">visibility</span>
                      View Details
                    </router-link>
                  </td>
                  <td class="connections-td-source">
                    <span class="font-weight-bold"
                      >{{ item.facebookAdAccount.accountName }} </span
                    ><br />
                    [{{ item.facebookAdAccount.accountId }}]
                  </td>
                  <td class="connections-td-destination">
                    <span class="font-weight-bold">{{
                      item.sa360Account.accountName
                    }}</span
                    ><br />
                    [{{ item.sa360Account.accountId }}]
                  </td>
                  <td class="connections-td-last">
                    {{ latestTaskRecordHandler(item.latestTaskRecord) }}
                  </td>
                  <td class="connections-td-btn dropdown-td">
                    <b-dropdown
                      id="dropdown-1"
                      right
                      variant="connections"
                      boundary="viewport"
                    >
                      <template #button-content>
                        <span class="material-icons">more_vert</span>
                      </template>
                      <b-dropdown-item :to="`/connections/${item.id}/view`">
                        <span class="dropdown-item-icon material-icons"
                          >visibility</span
                        >
                        <div class="dropdown-item-text">View Records</div>
                      </b-dropdown-item>

                      <b-dropdown-item :to="`/connections/${item.id}/edit`">
                        <span class="dropdown-item-icon material-icons"
                          >edit</span
                        >
                        <div class="dropdown-item-text">Edit</div>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click.prevent="
                          connectionStatusHandler(item.id, item.status)
                        "
                      >
                        <span class="dropdown-item-icon material-icons">
                          {{
                            item.status === 'ACTIVE'
                              ? 'toggle_off'
                              : 'toggle_on'
                          }}
                        </span>
                        <div class="dropdown-item-text">
                          Make
                          {{ item.status === 'ACTIVE' ? 'Inactive' : 'Active' }}
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            :current="currentPage"
            :total="connectionsLength"
            :perPage="perPage"
            @currentPageUpdate="currentPageUpdateHandler"
          />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SectionUserInfo from '../components/SectionUserInfo.vue'
import Pagination from '../components/Pagination.vue'

export default {
  name: 'Connections',
  components: { SectionUserInfo, Pagination },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Reporting Connections',
          active: true
        }
      ],
      connectionShow: false,
      //Pagination
      perPage: 5,
      currentPage: 1
    }
  },
  async mounted() {
    let status = await this['connection/pageHomeCreated']()
    if (status === 'success') {
      this.connectionShow = true
    } else {
      console.log('connection created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      user: state => state.user.self,
      connections: state => state.connection.list
    }),
    connectionShowHandler() {
      return this.connectionShow && this.connections.length
    },
    forClient() {
      return this.user.role === 'CLIENT'
    },
    //Pagination
    renderConnections() {
      let start = (this.currentPage - 1) * this.perPage
      let end = start + this.perPage
      return this.connections.slice(start, end)
    },
    connectionsLength() {
      return this.connections ? this.connections.length : 0
    }
  },
  methods: {
    ...mapActions([
      'connection/pageHomeCreated',
      'connection/pageHomeStatusHandler'
    ]),
    latestTaskRecordHandler(latestTaskRecord) {
      if (!latestTaskRecord) return ''
      return new Date(latestTaskRecord.reportDate).toLocaleDateString()
    },
    //Pagination
    currentPageUpdateHandler(currentPage) {
      this.currentPage = currentPage
    },
    async connectionStatusHandler(id, status) {
      let response = await this['connection/pageHomeStatusHandler']({
        id,
        status
      })
      if (response === 'success') {
        this.connectionShow = true
      } else {
        console.log('connection status change: ' + response)
        this.$router.push('/pages/page403')
      }
    }
  }
}
</script>
